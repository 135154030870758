define("discourse/plugins/discourse-group-tracker/discourse/connectors/group-edit/group-tracker-group-edit", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "discourse/lib/ajax"], function (_exports, _component, _tracking, _object, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class GroupTrackerGroupEdit extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "group", [_tracking.tracked], function () {
      return this.args.outletArgs.group;
    }))();
    #group = (() => (dt7948.i(this, "group"), void 0))();
    update(group, name, value) {
      group.set(name, value);
      return (0, _ajax.ajax)(`/admin/groups/${group.id}/${name}`, {
        type: "PUT",
        data: group.getProperties(name)
      });
    }
    trackPostsChanged(value) {
      this.update(this.group, "track_posts", value);
    }
    static #_2 = (() => dt7948.n(this.prototype, "trackPostsChanged", [_object.action]))();
    trackedPostPriorityGroup(value) {
      this.update(this.group, "track_posts_with_priority", value);
    }
    static #_3 = (() => dt7948.n(this.prototype, "trackedPostPriorityGroup", [_object.action]))();
    addToNavigationBarChanged(value) {
      this.update(this.group, "add_to_navigation_bar", value);
    }
    static #_4 = (() => dt7948.n(this.prototype, "addToNavigationBarChanged", [_object.action]))();
    trackedPostIconChanged(value) {
      this.update(this.group, "tracked_post_icon", value);
    }
    static #_5 = (() => dt7948.n(this.prototype, "trackedPostIconChanged", [_object.action]))();
  }
  _exports.default = GroupTrackerGroupEdit;
});