define("discourse/plugins/discourse-group-tracker/discourse/components/group-tracker-nav", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/object/computed", "@ember/service", "discourse/lib/url", "discourse/plugins/discourse-group-tracker/lib/group-tracker-icon", "@ember/template-factory"], function (_exports, _component, _component2, _object, _computed, _service, _url, _groupTrackerIcon, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="group-tracker-nav">
    {{#if this.groupTrackerPostsExist}}
      <DButton
        class="btn-default group-tracker-jump-prev"
        @action={{this.jumpToPrevTrackedPost}}
        @icon={{this.prevTrackerIcon}}
        @disabled={{this.prevTrackedPostDisabled}}
      >
        {{d-icon "arrow-left"}}
      </DButton>
      <DButton
        class="btn-default group-tracker-jump-next"
        @action={{this.jumpToNextTrackedPost}}
        @icon={{this.nextTrackerIcon}}
        @disabled={{this.nextTrackedPostDisabled}}
      >
        {{d-icon "arrow-right"}}
      </DButton>
    {{/if}}
  </div>
  */
  {
    "id": "CLwqLbwW",
    "block": "[[[10,0],[14,0,\"group-tracker-nav\"],[12],[1,\"\\n\"],[41,[30,0,[\"groupTrackerPostsExist\"]],[[[1,\"    \"],[8,[39,1],[[24,0,\"btn-default group-tracker-jump-prev\"]],[[\"@action\",\"@icon\",\"@disabled\"],[[30,0,[\"jumpToPrevTrackedPost\"]],[30,0,[\"prevTrackerIcon\"]],[30,0,[\"prevTrackedPostDisabled\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,2],[\"arrow-left\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n    \"],[8,[39,1],[[24,0,\"btn-default group-tracker-jump-next\"]],[[\"@action\",\"@icon\",\"@disabled\"],[[30,0,[\"jumpToNextTrackedPost\"]],[30,0,[\"nextTrackerIcon\"]],[30,0,[\"nextTrackedPostDisabled\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,2],[\"arrow-right\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null],[13]],[],false,[\"if\",\"d-button\",\"d-icon\"]]",
    "moduleName": "discourse/plugins/discourse-group-tracker/discourse/components/group-tracker-nav.hbs",
    "isStrictMode": false
  });
  class GroupTrackerNav extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.inject]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "postId", [(0, _computed.alias)("args.topic.currentPost")]))();
    #postId = (() => (dt7948.i(this, "postId"), void 0))();
    getPreviousTrackedPost() {
      const topic = this.args.topic;
      const postStream = topic.get("postStream");
      const stream = postStream.get("stream");
      return topic && topic.tracked_posts && topic.tracked_posts.slice().reverse().find(p => {
        return p.post_number < this.postId && stream.includes(postStream.findPostIdForPostNumber(p.post_number));
      });
    }
    getNextTrackedPost() {
      const topic = this.args.topic;
      const postStream = topic.get("postStream");
      const stream = postStream.get("stream");
      return topic && topic.tracked_posts && topic.tracked_posts.find(p => {
        return p.post_number > this.postId && stream.includes(postStream.findPostIdForPostNumber(p.post_number));
      });
    }
    get nextTrackedPostGroup() {
      const nextTrackedPost = this.getNextTrackedPost();
      return nextTrackedPost ? nextTrackedPost.group : null;
    }
    get nextTrackerIcon() {
      return (0, _groupTrackerIcon.default)(this.nextTrackedPostGroup, this.site, this.siteSettings);
    }
    get nextTrackedPostDisabled() {
      return this.nextTrackedPostGroup === null;
    }
    get groupTrackerPostsExist() {
      return this.nextTrackedPostGroup !== null || this.prevTrackedPostGroup !== null;
    }
    get prevTrackedPostGroup() {
      const prevTrackedPost = this.getPreviousTrackedPost();
      return prevTrackedPost ? prevTrackedPost.group : null;
    }
    get prevTrackerIcon() {
      return (0, _groupTrackerIcon.default)(this.prevTrackedPostGroup, this.site, this.siteSettings);
    }
    get prevTrackedPostDisabled() {
      return this.prevTrackedPostGroup === null;
    }
    jumpToNextTrackedPost() {
      const nextTrackedPost = this.getNextTrackedPost();
      if (nextTrackedPost) {
        const url = this.args.topic.url + "/" + nextTrackedPost.post_number;
        _url.default.routeTo(url);
      }
    }
    static #_4 = (() => dt7948.n(this.prototype, "jumpToNextTrackedPost", [_object.action]))();
    jumpToPrevTrackedPost() {
      const prevTrackedPost = this.getPreviousTrackedPost();
      if (prevTrackedPost) {
        const url = this.args.topic.url + "/" + prevTrackedPost.post_number;
        _url.default.routeTo(url);
      }
    }
    static #_5 = (() => dt7948.n(this.prototype, "jumpToPrevTrackedPost", [_object.action]))();
  }
  _exports.default = GroupTrackerNav;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, GroupTrackerNav);
});